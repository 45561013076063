#bootstrap-override {
    border-radius: 0px;
    margin-bottom: 0px;
    border: 0px;
    min-height: 55px;
    height: 55px;
    background-image: none;
    box-shadow: none;
    background: white;
}

.btn-link {
    text-decoration: none;
}

.nav {
    --bs-nav-link-padding-x: 0.6rem;
}

.nav-pills {
    --bs-nav-pills-link-active-bg: rgb(234, 234, 234);
    --bs-nav-pills-link-active-color: rgb(57, 52, 90)
}

.dirty#bootstrap-override {
    background: rgb(57, 52, 90);
    color: white;
}

.rdrDateDisplay {
    width: 332px
}

.collapsed .rdrMonthAndYearWrapper {
    display: none
}

.collapsed .rdrMonthsVertical {
    display: none
}

.dirty .navbar-brand {
    color: white;
}

.table-borderless>tbody>tr>td,
.table-borderless>tbody>tr>th,
.table-borderless>tfoot>tr>td,
.table-borderless>tfoot>tr>th,
.table-borderless>thead>tr>td,
.table-borderless>thead>tr>th {
    /* border: none; */
    padding: 16px;
    vertical-align: middle;
    font-weight: 400;
}

.table-borderless tr:first-child td:first-child {
    border-top-left-radius: 5px;
}

.table-borderless tr:first-child td:last-child {
    border-top-right-radius: 5px;
}

.table-borderless tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
}

.table-borderless tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
}

.table {
    --bs-table-bg: white;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.02)
}

.table-transparent {
    --bs-table-bg: transparent;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.02)
}


.thumbnail {
    height: 70px;
    width: 70px;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
    border-width: 0.5px;
    border-color: rgb(226, 226, 226);
    border-style: solid;
    overflow: hidden;
    padding: 0px;
    margin-bottom: 0px;
}

.thumbnailMissing {
    height: 70px;
    width: 70px;
    background-color: rgba(0, 0, 0, 0.005);
    border-radius: 5px;
    border-width: 0.5px;
    border-color: rgb(240, 240, 240);
    border-style: solid;
    overflow: hidden;
    padding: 0px;
    margin-bottom: 0px;
}

.thumbnail img {
    height: 70px;
    width: 70px;
    object-fit: contain;
}

body {
    background-color: rgb(250, 250, 250);
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

div .h5 {
    --bs-card-title-spacer-y: 1rem;
}

.colrow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
}

.colrow>[class*='col-'] {
    display: flex;
    flex-direction: column;
}

.kxmenuitem span {
    width: 30px;
    display: inline-block;
}

.kxmenuitem span span {
    width: 20px;
    display: inline-block;
    text-align: center;
}

.dirty .navbar-collapse.collapse.in {
    background-color: rgb(57, 52, 90);
    ;
}

.navbar-collapse.collapse.in {
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.navbar-default .navbar-nav>.nav-item>a {
    color: rgb(57, 52, 90);
}

.dirty .navbar-nav>.nav-item>a {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
}

.dirty .navbar-text {
    color: white;
    font-size: 18px;
}

.dirty p.navbar-text {
    /* margin-inline-end: unset;
    margin-inline-start: unset; */
    display: inline-block;
    /* margin-block-start: unset;
    margin-block-end: unset;     */
}

.dirty .btn-danger,
.dirty .btn-default {
    background-image: none;
    text-shadow: none;
    box-shadow: none;
}

.hovers {
    visibility: hidden;
}

.triggerHover:hover .hovers {
    visibility: visible;
}

.panel {
    background-color: rgb(250, 250, 250);
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:hover {
    border-left: 6px solid rgb(57, 52, 90);
}

.nav-pills .nav-link {
    font-size: 16px;
    font-weight: 600;
    border-left: 6px solid rgb(250, 250, 250);
}

.nav-pills .nav-link:hover {
    border-left: 6px solid rgb(238, 238, 238);
    background-color: rgb(238, 238, 238);
}


.nav-pills .nav-item.subitem .nav-link {
    font-size: 14px;
    font-weight: normal;
    border-left: 6px solid rgb(250, 250, 250);
    color: rgb(140, 140, 140)
}

.nav-pills .nav-item.subitem .nav-link:hover {
    color: rgb(57, 52, 90);
    border-left: 6px solid rgb(234, 234, 234);
}

.nav-pills .nav-item.subitem .nav-link.active,
.nav-pills .nav-item.active.subitem .nav-link:hover {
    border-left: 6px solid rgb(57, 52, 90);
    background-color: rgb(234, 234, 234);
    font-weight: 500;
    color: rgb(57, 52, 90)
}

.nav-link {
    color: rgb(57, 52, 90)
}

a {
    color: rgb(57, 52, 90)
}

a#a {
    background-color: rgb(251, 168, 60);
    clip-path: circle(17.5px at center);
    padding: 13px;
}

a#a::after {
    display: none;
}

.navbar-brand {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 15px;
    height: 55px;
}

.container {
    width: 100%;
}

.navbar-collapse .nav-item {
    padding: 10px;
}

.navbar-nav .nav-item a {
    line-height: 25px;
}

.narrow {
    text-align: center;
    width: 1%;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}

/* Fix for segmented control appearing above DateRangePicker */
.DateRangePicker_picker {
    z-index: 4;
    background-color: #fff;
    position: absolute;
}

/* Fix for segmented control appearing above DateRangePicker */
.DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 5;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 700px;
        margin: 30px auto;
    }
}

@media (min-width: 1024px) {
    .modal-dialog {
        width: 800px;
        margin: 30px auto;
    }
}

.nav-pills>li>a {
    position: relative;
    display: block;
    padding: 6px 10px;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #2e6fab;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    display: inline-block;
}

.dz-message {
    margin: 0px !important;
    color: "red";
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}