.listContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.backgroundContainer {
    display: flex;
}

.container {
    /* display: flex; */
    flex-direction: row;
}

.column {
    float: left;
    width: 50%;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
.label {
    margin-right: 10px; /* Adjust the margin as needed */
    font-weight: bold;  /* Optionally make the label bold */
    font-size: small;
}

.value {
    font-size: small;
}

.customFontButton {
    float: right;
}

.form {
    display: flex;
    flex-direction: column;
    min-width: 30%;
    padding-left: 60px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 50px;
}

.globalLabel {
    font-weight: bold;  /* Optionally make the label bold */
    font-size: small;
}

.globalValueContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px
}

.component-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #faf8f8;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px;
}

.info-container {
    display: flex;
    flex-direction: column;
}

.button-container {
    display: flex;
    align-items: center;
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.preview-container {
    display: flex;
    justify-content: center;
    background-color: #f6f7f7;
    width: 70%;
    border: 2px solid;
    border-color: rgb(242, 240, 240);
}

.preview {
    align-self: center;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    background-color: white;
    margin: 20px;
}
